import { Injectable } from '@angular/core';
import { CheckList } from './../../models/CheckList.dto';
import { CheckLists } from './../../models/Check-lists.dto';
import { CheckListsItem } from './../../models/CheckLists-Item.dto';
import { StorageService } from './../storage_service';

@Injectable()
export class CheckListsService {

    constructor(public storage: StorageService) {
    }

    createOrClearCheckLists(): CheckLists {
        const checkLists: CheckLists = {item: new CheckListsItem() };
        this.storage.setCheckList(checkLists);
        return checkLists;
    }

    getCheckLists(): CheckLists {
        let checkLists: CheckLists = this.storage.getCheckList();
        if (checkLists == null) {
            checkLists = this.createOrClearCheckLists();
        }
        return checkLists;
    }

    addCheckList(id: number, placa: string, checkList: CheckList): CheckLists {
        const checkLists = this.getCheckLists();

        checkLists.item.chId = id;
        checkLists.item.placa = placa;
        checkLists.item.checkList = checkList;

        this.storage.setCheckList(checkLists);
        return checkLists;
    }

    removeCheckList(id: number, placa: string, checkList: CheckList): CheckLists {
        let checkLists = this.getCheckLists();

        if (checkLists.item.chId === id) {
            checkLists = this.createOrClearCheckLists();
        }
        this.storage.setCheckList(checkLists);
        return checkLists;
    }
}
