import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { Cliente } from '../../models/Cliente.dto';
import { map, Observable } from 'rxjs';

@Injectable()
export class ClienteService {

    clienteUrl = `${API_CONFIG.baseUrl}/clientes`;
    viaCepUrl = `https://viacep.com.br/ws/`;

    constructor(public http: HttpClient) {
    }

    buscaClientePeloId(cliente_id: number) {
        return this.http.get<Cliente>(`${this.clienteUrl}/${cliente_id}`);
    }

    buscaClientePeloCodigo(cliente_codigo: string) {
        return this.http.get<Cliente>(`${this.clienteUrl}/filter/codigo/${cliente_codigo}`);
    }

    buscarClientePeloNome(cliente_nome: string) {
        return this.http.get<Cliente>(`${this.clienteUrl}/filter/nome/${cliente_nome}`);
    }

    buscarClientePeloCnpjCpf(cliente_cnpjCpf: string) {
        return this.http.get<Cliente>(`${this.clienteUrl}/cnpjCpf/${cliente_cnpjCpf}`);
    }

    buscarClientePeloEmail(email: string): Observable<Cliente> {
        return this.http.get<Cliente>(`${this.clienteUrl}/pesquisar/email/${email}`).pipe(
            map(response => response)
        );
    }

    insert(obj: Cliente) {
        return this.http.post(
            `${this.clienteUrl}`,
            obj,
            {
                observe: 'response'
            }
        );
    }

    update(cliente: Cliente): Observable<Cliente> {
        return this.http.put<Cliente>(`${this.clienteUrl}/${cliente.id}`, cliente);
    }

    buscarCep(cep: string) {
        const pesquisarCep = this.viaCepUrl + '/' + cep + '/json';
        return this.http.get<any>(pesquisarCep);
    }

}
