import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Tecnico } from './../../models/Tecnico.dto';


@Injectable()
export class TecnicoService {
  tecnicosUrl: string;

  constructor(private http: HttpClient) {
    this.tecnicosUrl = `${environment.apiUrl}/tecnicos`;
  }


  buscarTecnicoPorId(id: number): Observable<Tecnico> {
    return this.http.get<Tecnico>(`${this.tecnicosUrl}/${id}`);
  }

}
