export class FormaPagamento {
    id: number;
    codigo: string;
    descricao: string;
    calculaJuros: string;
    desdobramento: string;
    observacoes: string;
    percentualEntrada: number;
    prazomedio: number;
    quantidadeMaxParcelas: number;
    relatorioVistaPrazo: string;
    tipoPagamentoNfe: string;
    tipoVistaPrazo: string;

    constructor(
        id?: number,
        codigo?: string,
        descricao?: string,
        calculaJuros?: string,
        desdobramento?: string,
        observacoes?: string,
        percentualEntrada?: number,
        prazomedio?: number,
        quantidadeMaxParcelas?: number,
        relatorioVistaPrazo?: string,
        tipoPagamentoNfe?: string,
        tipoVistaPrazo?: string
    ) {
        this.id = id;
        this.codigo = codigo;
        this.descricao = descricao;
        this.calculaJuros = calculaJuros;
        this.desdobramento = desdobramento;
        this.observacoes = observacoes;
        this.percentualEntrada = percentualEntrada;
        this.prazomedio = prazomedio;
        this.quantidadeMaxParcelas = quantidadeMaxParcelas;
        this.relatorioVistaPrazo = relatorioVistaPrazo;
        this.tipoPagamentoNfe = tipoPagamentoNfe;
        this.tipoVistaPrazo = tipoVistaPrazo;
    }
}

