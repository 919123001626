import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage_service';
import { LocalUser } from './../models/Local_User';
import { CartService } from './domain/cart.service';
import { CartOrderService } from './domain/cartOrderService';
import { Credencial } from './../models/Credencial.dto';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, throwError } from 'rxjs';
@Injectable()
export class AuthService {

    oauthTokenUrl = `${environment.apiUrl}/oauth/token`;
    tokensRenokeUrl = `${environment.apiUrl}/tokens/revoke`;
    jwtPayload: any;
    jwtHelper = new JwtHelperService();

    constructor(
        public http: HttpClient,
        private storage: StorageService,
        private cartService: CartService,
        private cartOrderService: CartOrderService,
        public navCtrl: NavController,
        public router: Router
    ) {
    }

    authenticate(creds: Credencial) {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');

        const body = `username=${creds.email}&password=${creds.senha}&grant_type=password`;
        return this.http.post<any>(
            this.oauthTokenUrl,
            body,
            {
                headers,
                withCredentials: true
            }
        );
    }

    successfulLogin(token: string) {
        const user: LocalUser = {
            token: token,
            email: this.jwtPayload.email,
            codigo: this.jwtPayload.codigo
        };
        this.storage.setLocalUser(user);
        this.cartService.createOrClearCart();
        this.cartOrderService.createOrClearCartService();
    }

    obterNovoAccessToken(): Observable<any> {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');

        const body = 'grant_type=refresh_token';
        return this.http.post<any>(this.oauthTokenUrl, body,
            { headers, withCredentials: true })
            .pipe(
                catchError(() => {
                    console.error('Erro ao renovar token.');
                    return throwError(() => new Error('Erro ao renovar token!'));
                })
            );
    }

    verificarToken(): Observable<any> {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');

        return this.http.post<any>(this.oauthTokenUrl, { headers })
            .pipe(
                map(() => true),
                catchError(() => of(false))
            );
    }


    refreshToken() {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');

        const body = 'grant_type=refresh_token';

        return this.http.post<any>(this.oauthTokenUrl,
            body,
            {
                headers,
                withCredentials: true
            }
        );
    }

    isAccessTokenInvalido(): boolean {
        const token = localStorage.getItem('token');
        return this.jwtHelper.isTokenExpired(token);
    }

    logout() {
        this.removeToken().subscribe
            (response => {
              this.limpaStorageRedirectLogin();
            });

    }

    removeToken() {
        return this.http.delete(this.tokensRenokeUrl, { withCredentials: true });
    }

    limpaStorageRedirectLogin() {
        this.jwtPayload = null;
        this.storage.setCart(null);
        this.storage.setCartService(null);
        this.storage.setLocalUser(null);
        this.router.navigateByUrl('/', { replaceUrl: true });
    }
}
