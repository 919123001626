import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { FormaPagamento } from './../../models/FormaPagamento.dto';

export class FormaPagamentoFiltro {
    descricao: string;
    codigo: string;
    pagina = 0;
    itensPorPagina = 10;
  }

@Injectable()
export class FormaPagamentoService {

    formasPagamentosUrl: string;

    constructor(public http: HttpClient) {
        this.formasPagamentosUrl = `${API_CONFIG.baseUrl}/formasPagamentos`;
    }

    pesquisar(filtro: FormaPagamentoFiltro): Promise<any> {

        let formasPagamentosUrlPesquisar = `${this.formasPagamentosUrl}/filter/descricao`;

        let params = new HttpParams({
          fromObject: {
            page:  filtro.pagina.toString(),
            size: filtro.itensPorPagina.toString()
          }
        });

        if (filtro.descricao) {
          params = params.append('descricao', filtro.descricao);
        } else if (filtro.codigo) {
          params = params.append('codigo', filtro.codigo);
          formasPagamentosUrlPesquisar = `${this.formasPagamentosUrl}/filter/codigo`;
        }

        return this.http
          .get<any>(formasPagamentosUrlPesquisar, { params })
          .toPromise()
          .then(response => {
            const formasPagamentos = response.content;

            const resultado = {
              formasPagamentos,
              total: response.totalElements
            };

            return resultado;
          });
      }

      listarTodas() {
        return this.http.get<FormaPagamento[]>(`${this.formasPagamentosUrl}/lista`);
      }

      adicionar(formaPagamento: FormaPagamento) {
        return this.http.post(this.formasPagamentosUrl, formaPagamento);
      }

      atualizar(formaPagamento: FormaPagamento) {
        return this.http
          .put(`${this.formasPagamentosUrl}/${formaPagamento.id}`, FormaPagamento);
      }

      buscarPorId(id: number) {
        return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/${id}`);
      }

      buscarPeloCodigo(codigo: string) {
        return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/buscarPeloCodigo/${codigo}`);
      }

      buscarPorCodigo(codigo: string) {
        return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/descricaoPagamento/${codigo}`);
      }

      excluir(id: number) {
        return this.http
          .delete(`${this.formasPagamentosUrl}/${id}`);
      }

}
