import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { ClienteFormaPagamento } from '../../models/ClienteFormaPagamento.dto';
import { Observable } from 'rxjs';


@Injectable()
export class ClienteFormaPagamentoService {

  private clienteFormaPagamentoUrl: string;

  constructor (
    private http: HttpClient
  ) {
    this.clienteFormaPagamentoUrl =  `${API_CONFIG.baseUrl}/clienteFormasPagamentos`;
  }

  adicionar(clienteFormaPagamento: ClienteFormaPagamento): Observable<ClienteFormaPagamento> {
    return this.http.post<ClienteFormaPagamento>(this.clienteFormaPagamentoUrl, clienteFormaPagamento);
  }

  atualizar(clienteFormaPagamento: ClienteFormaPagamento): Observable<ClienteFormaPagamento> {
    return this.http.put<ClienteFormaPagamento>(`${this.clienteFormaPagamentoUrl}/${clienteFormaPagamento.id}`, clienteFormaPagamento);
  }

  buscarPeloId(id: number): Observable<ClienteFormaPagamento> {
    return this.http.get<ClienteFormaPagamento>(`${this.clienteFormaPagamentoUrl}/${id}`);
  }

  deletarPeloId(id: number): Observable<any> {
    return this.http.delete<any>(`${this.clienteFormaPagamentoUrl}/${id}`);
  }

  deletarPeloCodigoPagamento(clienteFormPgto: ClienteFormaPagamento): Observable<any> {
    return this.http.delete<any>(`${this.clienteFormaPagamentoUrl}/deletarPeloCodigoPagamento/
      ${clienteFormPgto.codigo}/${clienteFormPgto.codigoPagamento}`);
  }

  listarPeloCodigo(codigo: string) {
    return this.http.get<ClienteFormaPagamento[]>(`${this.clienteFormaPagamentoUrl}/listarPeloCodigo/${codigo}`);
  }



}
