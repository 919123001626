import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../services/storage_service';
import { FieldMessage } from '../models/FieldMessage.dto';
import { AlertController, NavController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(public storage: StorageService,
        public alertCtrl: AlertController,
        private navCtrl: NavController,
        private auth: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {

                let errorObj = error;
                if (errorObj.error) {
                    errorObj = errorObj.error;
                }
                if (!errorObj.status) {
                    if (errorObj.error === 'invalid_token') {
                        this.showTokenExpirado();
                    } else {
                        errorObj = JSON.parse(errorObj.error);
                    }
                }

                console.log('Erro detectado pelo interceptor:');
                console.log(errorObj);

                switch (errorObj.status) {
                    case 401:
                        this.handle401();
                        break;

                    case 403:
                        this.handle403();
                        break;

                    case 422:
                        this.handle422(errorObj);
                        break;

                    default:
                        this.handleDefaultEror(errorObj);
                }

                // retorna um Observable com um valor padrão
                return of({ status: 500, message: 'Ocorreu um erro ao processar a solicitação.' });
            })) as any;
    }

    handle403() {
        this.storage.setLocalUser(null);
    }

    handle401() {
        console.log('Erro 401 detectado pelo interceptor:');
    }

    handle422(errorObj: any) {
        console.log('Erro 422 detectado pelo interceptor:');
        /*let alert = this.alertCtrl.create({
            title: 'Validação',
            message: this.listErrors(errorObj.errors),
            enableBackdropDismiss: false,
            buttons: [
                {
                    text: 'Ok'
                }
            ]
        });
        alert.present();*/
    }

    handleDefaultEror(errorObj: any) {
        console.log('Erro generico detectado pelo interceptor:');
        /* let alert = this.alertCtrl.create({
             title: 'Erro ' + errorObj.status + ': ' + errorObj.error,
             message: errorObj.message,
             enableBackdropDismiss: false,
             buttons: [
                 {
                     text: 'Ok'
                 }
             ]
         });
         alert.present();*/
    }

    listErrors(messages: FieldMessage[]): string {
        let s = '';
        for (let i = 0; i < messages.length; i++) {
            s = s + '<p><strong>' + messages[i].fieldName + '</strong>: ' + messages[i].message + '</p>';
        }
        return s;
    }

    async showTokenExpirado() {
        const alert = await this.alertCtrl.create({
            header: 'Sessão Expirada',
            message: 'Faça o login novamente!',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        this.auth.limpaStorageRedirectLogin();
                    }
                }
            ]
        });
        await alert.present();
    }
}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
