import { PerfilAcesso } from './PerfilAcesso.dto';

export class Usuario {
    codigo?: number;
    nome?: string;
    email?: string;
    cpfCnpj?: string;
    empresa?: string;
    login?: string;
    senha?: string;
    perfilAcesso?: PerfilAcesso;
    codigo_bloqueio?: string;
    token?: string;
    imageUrl?: string;
    tipo_usuario?: boolean;
    notificacoes?: boolean;
    localizacao?: boolean;
    promocoes?: boolean;
    notificawhatsapp?: boolean;

    constructor(params?: Partial<Usuario>) {
      Object.assign(this, params);
    }
  }
