import { Observable, BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckList } from '../../models/CheckList.dto';
import { CheckListsService } from './checkLists.service';
import { CheckListsItem } from 'src/models/CheckLists-Item.dto';

@Injectable()
export class CheckListService {

    checkListUrl: string;
    private dados = new BehaviorSubject({});


    constructor(private http: HttpClient, private checkListsService: CheckListsService) {
        this.checkListUrl = `${environment.apiUrl}/checkList`;
    }

    setDados({ checkList }: { checkList: CheckList; }): CheckListsItem {
        this.dados.next(checkList);
     /*   this.checkListsService.createOrClearCheckLists();
        return this.checkListsService.addCheckList(
            checkList.id, checkList.placaVeiculo.placa, checkList).item;*/
            return null;
    }

    getDados(): Observable<any> {
        return this.dados.asObservable();
    }

    buscarPorId(checklist_id: number): Observable<CheckList> {
        return this.http.get<CheckList>(`${this.checkListUrl}/${checklist_id}`);
    }

    buscarPorClientePlaca(cliente_id: number, placa_id: number): Observable<CheckList> {
        return this.http.get<CheckList>(`${this.checkListUrl}/pecaPeloApp/${cliente_id}/${placa_id}`);
    }

    listarPorPlaca(placa_id: number): Observable<CheckList[]> {
        return this.http.get<CheckList[]>(`${this.checkListUrl}/pecaPeloApp/fechado/${placa_id}`);
    }

    adcionar(checklist: CheckList): Observable<CheckList> {
        return this.http.post<CheckList>(this.checkListUrl, checklist);
    }

    atualizar(checklist: CheckList): Observable<CheckList> {
        return this.http.put<CheckList>(`${this.checkListUrl}/${checklist.id}`, checklist);
    }

    adicionarImagem(file: File, directory: string): Observable<any> {
        const formdata: FormData = new FormData();
        formdata.append('file', file);
        return this.http.post(`${environment.apiUrl}/checkList/upload/${directory}`, formdata);
    }

    deletarImagem(directory: string, fileName: string): Observable<any> {
        return this.http.delete(`${this.checkListUrl}/delete/${directory}/${fileName}`);
    }

    alterarImagem(file: File, directory: string): Observable<any> {

        const formdata: FormData = new FormData();

        formdata.append('file', file);

        return this.http.put(`${this.checkListUrl}/update/${directory}`, formdata);
    }

    buscarImagem(directory: string, fileName: string): Observable<Blob> {
        const headers: HttpHeaders = new HttpHeaders({
          'Content-Type': 'image/jpeg',
          'Accept': 'image/jpeg'
        });

        return this.http.get(`${environment.apiUrl}/checkList/download/checklists/checklist/${fileName}`,
          {headers: headers, responseType: 'blob'}).pipe(
            map((res: any) => res)
          );
      }

    /*  buscarImagem(directory: string, fileName: string): Observable<Blob> {
          const headers: HttpHeaders = new HttpHeaders({
              'Content-Type': 'image/jpeg',
              'Accept': 'image/jpeg'
          });
          return this.http.get<Blob>(`${this.checkListUrl}/download/${directory}/${fileName}`,
              { headers: headers, responseType: 'blob' });
      }

      private findImageLadoEsquerdo() {
          this.checkListService.buscarImagem('checklist', `ladoesquerdo-${this.checkList.id}.jpg`)
            .then(image => {
              this.getBase64(image)
                .then(imageBase64 => this.imageBase64LadoEsquerdo = imageBase64)
                .catch(() => {
                  this.imageBase64LadoEsquerdo = null;
                  this.toasty.error('Falha ao converter a Imagem do Produto!');
                });
            })
            .catch((erro) => {
              this.imageBase64LadoEsquerdo = null;
            });
        } */
}

