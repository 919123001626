import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { Estado } from './../../models/Estado.dto';
import { Observable } from 'rxjs';

@Injectable()
export class EstadoService {

    estadoUrl: string;

    constructor(public http: HttpClient) {
        this.estadoUrl = `${API_CONFIG.baseUrl}/estados`;
    }

    buscarEstados(): Observable<Estado[]>  {
        return this.http.get<Estado[]>(`${this.estadoUrl}/`);
    }
}
