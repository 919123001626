import { StorageService } from './../../../services/storage_service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    NavController, MenuController, ToastController,
    AlertController, LoadingController
} from '@ionic/angular';
import { Credencial } from '../../../models/Credencial.dto';
import { ClienteFormaPagamento } from './../../../models/ClienteFormaPagamento.dto';
import { ClienteService } from './../../../services/domain/cliente.service';
import { ClienteFormaPagamentoService } from './../../../services/domain/clienteFormaPagamentoService';
import { AuthService } from '../../../services/auth.service';
import { firstValueFrom, map } from 'rxjs';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Usuario } from 'src/models/Usuario.dto';
import { UsuarioService } from './../../../services/domain/usuario.service';
import { Cliente } from './../../../models/Cliente.dto';
import { PerfilAcesso } from './../../../models/PerfilAcesso.dto';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
    public onLoginForm: FormGroup;
    isGoogleLoggedIn = false;
    jwtHelper: JwtHelperService = new JwtHelperService();
    creds: Credencial = {
        email: '',
        senha: ''
    };
    showMenu = false;
    constructor(
        private router: Router,
        public navCtrl: NavController,
        public menuCtrl: MenuController,
        public toastCtrl: ToastController,
        private storage: StorageService,
        private usuarioService: UsuarioService,
        private clienteService: ClienteService,
        private clienteformaPagamentoService: ClienteFormaPagamentoService,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        private formBuilder: FormBuilder,
        public auth: AuthService,
    ) {
        GoogleAuth.initialize({
            clientId: '781672943238-9aa67ncv5vfoilfemdqn4q0g8uqbda8j.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        });
    }

    ionViewDidEnter() {
        this.menuCtrl.enable(false);
    }

    ionViewDidLeave(): void {
        this.menuCtrl.enable(true);
    }

    ngOnInit() {
        this.onLoginForm = this.formBuilder.group({
            'email': [null, Validators.compose([
                Validators.required
            ])],
            'password': [null, Validators.compose([
                Validators.required
            ])]
        });
    }

    async forgotPass() {
        const alert = await this.alertCtrl.create({
            header: 'Esqueceu sua senha?',
            message: 'Digite seu endereço de e-mail para envio de link de redefinição.',
            inputs: [
                {
                    name: 'email',
                    type: 'email',
                    placeholder: 'Email'
                }
            ],
            buttons: [
                {
                    text: 'Cancela',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirma Cancelamento');
                    }
                }, {
                    text: 'Confirma',
                    handler: async () => {
                        const loader = await this.loadingCtrl.create({
                            duration: 2000
                        });

                        loader.present();
                        loader.onWillDismiss().then(async l => {
                            const toast = await this.toastCtrl.create({
                                message: 'O email foi enviado com sucesso.',
                                duration: 3000,
                                position: 'bottom'
                            });

                            toast.present();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    goToRegister() {
        this.navCtrl.navigateRoot('pages/register/register');
    }

    goToHome() {
        this.router.navigateByUrl('pages/folder');
        //    this.navCtrl.navigateRoot('pages/folder');
    }


    async login() {
        this.creds.email = this.onLoginForm.controls['email'].value;
        this.creds.senha = this.onLoginForm.controls['password'].value;

        try {
            const response = await firstValueFrom(this.auth.authenticate(this.creds));
            this.auth.jwtPayload = this.jwtHelper.decodeToken(response.access_token);
            this.auth.successfulLogin(response.access_token);
            this.goToHome();
        } catch (error) {
            let msg = '';
            if (error && error instanceof SyntaxError && error.message.includes('Unexpected token')) {
                msg = 'Email ou senha incorretos';
            } else {
                msg = 'A conexão foi recusada, verifique se o dispositivo está conectado a uma rede e possui internet!';
            }

            const alert = await this.alertCtrl.create({
                header: 'Falha na autenticação',
                message: msg,
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Ok'
                    }
                ]
            });
            alert.present();
        }
    }

    async loginWithGoogle() {
        const googleUser = await GoogleAuth.signIn();
        this.onLoginForm.controls['email'].setValue(googleUser.email);
        this.logInContaGoogle(googleUser);
    }

    logInContaGoogle(googleUser: any) {
        this.usuarioService.findByEmailGoogle(googleUser.email).subscribe(user => {
            if (user !== undefined && user !== null) {
                this.hideLoader();
                this.auth.jwtPayload = this.jwtHelper.decodeToken(googleUser.authentication.idToken);
                this.auth.successfulLogin(googleUser.authentication.idToken);
                this.goToHome();
            } else {
                this.loadDataGoogle(googleUser);
            }
        });
    }

    loadDataGoogle(googleUser: any) {
        const usuario = new Usuario();
        const cliente = new Cliente();
        const perfil = new PerfilAcesso();
        perfil.id = 1;
        cliente.empresa = 'C';
        cliente.nome = googleUser.displayName;
        cliente.tipoPessoa = 'A';
        cliente.email = googleUser.email;
        cliente.posicao = 'AN';
        cliente.filial = 'N';
        cliente.desconto = 'N';
        cliente.percentualDesconto = 30.000;
        cliente.emailEnvioDanfeXml = googleUser.email;
        cliente.descontoVarejo = 'S';
        cliente.descontoAtacado = 'N';
        usuario.nome = cliente.nome;
        usuario.tipo_usuario = false;
        usuario.notificawhatsapp = false;
        usuario.senha = '123456';
        usuario.email = cliente.email;
        usuario.login = cliente.email.substring(0, 20);
        usuario.empresa = cliente.empresa;
        usuario.perfilAcesso = perfil;
        console.log(cliente);
        console.log(usuario);
        this.registerUser(usuario, cliente, googleUser);
    }

    registerUser(usuario: Usuario, cliente: Cliente, googleUser: any) {
        this.presentLoading('Bem Vindo! Salvando dados da sua conta! Aguarde...');
        console.log('salvar');
        if (usuario != null && usuario.email !== undefined) {
            this.usuarioService.insert(usuario)
                .subscribe(() => {
                    this.registerClient(cliente, googleUser);
                });
        }
    }

    registerClient(cliente: Cliente, googleUser: any) {
        this.clienteService.insert(cliente)
            .subscribe(response => {
                cliente = response.body as Cliente;
                this.registerFormPayment(cliente.codigo, googleUser);
            });
    }

    registerFormPayment(cliente_codigo: string, googleUser: any) {
        const clienteFormaPgtoAvista = new ClienteFormaPagamento(cliente_codigo, '00');
        const clienteFormaPgtoCartaoDebito = new ClienteFormaPagamento(cliente_codigo, '01');
        const clienteFormaPgtoCartaCredito1X = new ClienteFormaPagamento(cliente_codigo, '02');
        const clienteFormaPgtoCartaCredito2X = new ClienteFormaPagamento(cliente_codigo, '03');
        const clienteFormaPgtoCartaCredito3X = new ClienteFormaPagamento(cliente_codigo, '04');
        const clienteFormaPgtoFaturado = new ClienteFormaPagamento(cliente_codigo, '75');
        const clienteFormaPgtoPix = new ClienteFormaPagamento(cliente_codigo, '76');

        this.clienteformaPagamentoService.adicionar(clienteFormaPgtoAvista)
            .subscribe(response => {
                this.clienteformaPagamentoService.adicionar(clienteFormaPgtoCartaCredito1X)
                    .subscribe(() => {
                        this.clienteformaPagamentoService.adicionar(clienteFormaPgtoCartaCredito2X)
                            .subscribe(() => {
                                this.clienteformaPagamentoService.adicionar(clienteFormaPgtoCartaCredito3X)
                                    .subscribe(() => {
                                        this.clienteformaPagamentoService.adicionar(clienteFormaPgtoCartaoDebito)
                                            .subscribe(() => {
                                                this.clienteformaPagamentoService.adicionar(clienteFormaPgtoFaturado)
                                                    .subscribe(() => {
                                                        this.clienteformaPagamentoService.adicionar(clienteFormaPgtoPix)
                                                            .subscribe(() => {
                                                                this.hideLoader();
                                                                this.auth.jwtPayload = this.jwtHelper
                                                                    .decodeToken(googleUser.authentication.idToken);
                                                                this.auth.successfulLogin(googleUser.authentication.idToken);
                                                                this.navCtrl.navigateRoot('pages/edit-profile/edit-profile');
                                                            });
                                                    });
                                            });
                                    });
                            });
                    });
            });
    }

    async presentLoading(msg: string) {
        const loader = await this.loadingCtrl.create({
            message: msg
        });
        loader.present();
    }

    hideLoader() {
        this.loadingCtrl.dismiss().then((res) => {
            console.log(res);
        }).catch((error) => {
            console.log('error', error);
        });
    }

    async presentToast(position: 'top' | 'middle' | 'bottom', msg: string) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 2000,
            cssClass: 'custom-toast',
            icon: 'construct',
            position: position
        });

        await toast.present();
    }


}
