
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { PreVendaItem } from './../../models/PreVendaItem.dto';

@Injectable()
export class PreVendaItemService {

   preVendaItemUrl: string;

   constructor(private http: HttpClient) {
       this.preVendaItemUrl = `${API_CONFIG.baseUrl}/preVendaItens`;
   }


   totalPreVendaConcluida(preVendaItens: PreVendaItem[]): number {
    if (!preVendaItens) {
        return 0;
    }
       let sum = 0;
       for (let i = 0; i < preVendaItens.length; i++) {
        if (preVendaItens[i] && preVendaItens[i].produto) {
           sum += (preVendaItens[i].produto.precoVenda * preVendaItens[i].quantidade);
        }
       }
       return sum;
   }
}
