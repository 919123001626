import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { Observable } from 'rxjs';
import { Vendedor } from '../../models/Vendedor.dto';

@Injectable()
export class VendedorService {

    vendedorUrl: string;

    constructor(public http: HttpClient) {
        this.vendedorUrl = `${API_CONFIG.baseUrl}/vendedores`;
    }

    buscarVendedores(): Observable<Vendedor[]>  {
        return this.http.get<Vendedor[]>(`${this.vendedorUrl}/`);
    }

    buscarVendedorPeloCodigo(codigo: string) {
        return this.http.get(`${this.vendedorUrl}/buscarPeloCodigo/${codigo}`);
    }
}
