import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { AuthService } from './../services/auth.service';
import { StorageService } from './../services/storage_service';
import { UsuarioService } from './../services/domain/usuario.service';
import { Network } from '@ionic-native/network/ngx';
import { NotFoundPartPage } from './pages/not-found-part/not-found-part.page';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [IonicModule],
})
export class AppComponent {
    [x: string]: any;

    public appPages = [
        {
            title: 'Dashboard',
            url: '/pages/Inbox',
            direct: 'root',
            icon: 'bar-chart'
        },
        {
            title: 'Peças Automotivas',
            url: '/pages/find-parts/find-parts',
            direct: 'root',
            icon: 'settings'
        },
        {
            title: 'Serviços de Reparos',
            url: '/pages/service/find-services',
            direct: 'root',
            icon: 'construct'
        },
        {
            title: 'Checklist Veículo',
            url: '/pages/service/check-list',
            direct: 'root',
            icon: 'checkbox'
        },
        {
            title: 'Meus Pedidos',
            url: '/pages/list-ordes/list-orders',
            icon: 'receipt'
        },
        {
            title: 'Especialistas',
            url: '/pages/companys/companys',
            direct: 'root',
            icon: 'location'
        },
        {
            title: 'Configurações',
            url: '/pages/settings/settings',
            direct: 'root',
            icon: 'options'
        },
        {
            title: 'Sobre',
            url: '/pages/about/about',
            direct: 'forward',
            icon: 'information-circle'
        },
    ];

    imageSrc: string | null;
    isConnected = true;

    constructor(
        public navCtrl: NavController,
        public auth: AuthService,
        public router: Router,
        public menuCtrl: MenuController,
        public storage: StorageService,
        public usuarioService: UsuarioService,
        private network: Network,
        private modalCtrl: ModalController
    ) {
        this.initializeApp();
        this.initializeFirebase();
    }

    showImagePerfil() {
        this.findImagePerfil();
    }

    async initializeApp() {
        console.log('INICIALIZAR APP');
        this.checkConnection();
        console.log(this.isConnected);
        /*  if (this.auth.isAccessTokenInvalido) {
              const token = localStorage.getItem('token');
              console.log(this.auth.jwtHelper.isTokenExpired);
              console.log(this.auth.jwtHelper.getTokenExpirationDate(token));
              console.log('token invalido');
              this.auth.limpaStorageRedirectLogin();
              return true;
          } else {
            console.log('token Valido');
            return false;
          }*/
        /*   this.platform.ready().then(() => {
             SplashScreen.hide();
           });*/
    }

    public async initializeFirebase(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            return;
        }
        initializeApp(environment.firebase);
    }

    checkConnection() {
        this.network.onDisconnect().subscribe(() => {
            this.isConnected = false;
            this.showOfflineModal();
            console.log('Você está sem conexão com a internet!');
        });

        this.network.onConnect().subscribe(() => {
            this.isConnected = true;
            console.log('Você está conectado a internet!');
        });
    }


    goToEditProfile() {
        this.navCtrl.navigateForward('pages/edit-profile/edit-profile');
    }

    logout() {
        this.auth.logout();
    }

    findImagePerfil() {
        const user_codigo = this.storage.getLocalUser().codigo;
        this.usuarioService.buscarImagem('usuario', `perfil-${user_codigo}.jpg`)
            .subscribe({
                next: (image: Blob) => {
                    this.getBase64(image)
                        .then(imageBase64 => this.imageSrc = imageBase64)
                        .catch(() => {
                            this.imageSrc = null;
                        });
                },
                error: (erro) => {
                    this.imageSrc = null;
                }
            });
    }

    private getBase64(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async showOfflineModal() {
        const modal = await this.modalCtrl.create({
            component: NotFoundPartPage,
            cssClass: 'offline-modal',
            backdropDismiss: false
        });
        return await modal.present();
    }


}
