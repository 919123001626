import { CheckList } from './CheckList.dto';
export class CheckListsItem {
    chId: number;
    placa: string;
    checkList: CheckList;
    constructor(
        chId?: number,
        placa?: string,
        checkList = new CheckList()
    ) {
        this.chId = chId;
        this.placa = placa;
        this.checkList = checkList;
    }
}
