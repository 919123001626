export const environment = {
    production: false,
    apiUrl: 'https://api.infinityweb.work',

    tokenWhitelistedDomains: [new RegExp('infinityweb.work')],
    tokenBlacklistedRoutes: [new RegExp('\/oauth\/token')],

    firebase: {
        apiKey: 'AIzaSyASfcQ5RhnKNk9JKpAtNMmgARIbTMP3xuE',
        authDomain: 'chat-app-a7d2f.firebaseapp.com',
        projectId: 'chat-app-a7d2f',
        storageBucket: 'chat-app-a7d2f.appspot.com',
        messagingSenderId: '119165873322',
        appId: '1:119165873322:web:090b847cc7db33e44934d3',
        vapidKey: 'BLDNk_8AvMeKVUrUPtXTIDCd7-Rq7zoQEfZkFkumeihXiqbN7pkKmASP5AysjB0DLQY31Z9Q_cr-wygbUMSi5Cg'
      },
};
