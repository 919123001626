import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { PreVenda } from '../../models/PreVenda.dto';
import { ClienteService } from './cliente.service';
import { CartService } from './cart.service';
import { CodigoLeiNcm } from './../../models/CodigoLeiNcm.dto';
import { DetImp2 } from './../../models/DtImp2.dto';
import { Observable } from 'rxjs';

export class PreVendaFiltro {
    empresa: string;
    sequencia: string;
    valorMinimo: number;
    dataDe: Date;
    dataAte: Date;
    codigoNatOper: string;
    documento: string;
    vendedor: string;
    pagina = 0;
    itensPorPagina = 10;
    constructor(
        empresa: string,
        sequencia: string,
        valorMinimo: number,
        dataDe: Date,
        dataAte: Date,
        codigoNatOper: string,
        documento: string,
        vendedor: string,
        pagina = 0,
        itensPorPagina = 10,
    ) {
        this.empresa = empresa;
        this.sequencia = sequencia;
        this.valorMinimo = valorMinimo;
        this.dataDe = dataDe;
        this.dataAte = dataAte;
        this.codigoNatOper = codigoNatOper;
        this.documento = documento;
        this.vendedor = vendedor;
        pagina = 0;
        itensPorPagina = 10;
    }
}

@Injectable()
export class PreVendaService {

    preVendaUrl: string;
    codigoLeiNcmUrl: string;
    detimp2Url: string;

    constructor(private http: HttpClient,
        private clienteService: ClienteService,
        private cartService: CartService) {
        this.preVendaUrl = `${API_CONFIG.baseUrl}/preVendas`;
        this.codigoLeiNcmUrl = `${API_CONFIG.baseUrl}/codigosLeiNcm`;
        this.detimp2Url = `${API_CONFIG.baseUrl}/dtImp2Url`;
    }

    insert(obj: PreVenda) {
        return this.http.post(
            `${this.preVendaUrl}`,
            obj,
            {
                observe: 'response',
            }
        );
    }

    buscarPorId(preVenda_id: string) {
        return this.http.get<PreVenda>(`${this.preVendaUrl}/${preVenda_id}`);
    }

    listaPedidosPorCodigoCliente(codigo: string) {
        return this.http.get<PreVenda[]>(`${this.preVendaUrl}/listaPorCliente/${codigo}`);
    }

    listaPedidosPorCodigoClienteNeGerada(codigo: string) {
        return this.http.get<PreVenda[]>(`${this.preVendaUrl}/listaPorCliente/NeGerada/${codigo}`);
    }

    buscarCodigoLeiNcm(ncm: string): Observable<CodigoLeiNcm[]> {
        return this.http.get<CodigoLeiNcm[]>(`${this.codigoLeiNcmUrl}/lista/${ncm}`);
    }

    buscarCodigoFiscalOperacao(filtro: DetImp2): Observable<DetImp2> {
        const params = new HttpParams({
            fromObject: {
                origem: filtro.origem,
                destino: filtro.destino,
                codigoNat: filtro.codigoNat,
                rte: filtro.rte,
                contribuinte: filtro.contribuinte,
                revendedor: filtro.revendedor,
                suframa: filtro.suframa,
                produtoRural: filtro.produtorRural,
                codigoLei: filtro.codigoLei,
                derivadoPetroleo: filtro.derivadoPetroleo
            }
        });
        return this.http.get<DetImp2>(`${this.detimp2Url}?cfop`, { params });

    }

    recalcularPrecos(preVenda: PreVenda): Promise<PreVenda> {
        return this.http.put<PreVenda>(`${this.preVendaUrl}/recalcular/${preVenda.id}`, preVenda).toPromise();
    }

    calcularPrazoMedio(desdobramento: string): number {
        const desdobra = desdobramento;
        const prazos = [];
        let totalDias = 0;
        let qtdParcelas = 1;

        totalDias = parseFloat(desdobra.substring(0, 3));
        prazos.push(totalDias);

        for (let i = 3; i < desdobra.length; ++i) {
          if (desdobra.substring(i, 1) === '/') {
            prazos.push(desdobra.substring(i + 1, i + 4));
            if (desdobra.substring(i + 1, i + 4) !== '   ') {
              totalDias = totalDias + parseFloat(desdobra.substring(i + 1, i + 4));
              qtdParcelas++;
            }
          }
        }

        return (totalDias / qtdParcelas) <= 0 ? 0 : (totalDias / qtdParcelas);
    }

}


