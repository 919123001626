import { Injectable } from '@angular/core';
import { StorageService } from './../storage_service';
import { CartService } from './../../models/Cart-Service.dto';
import { ServicoVeiculo } from './../../models/ServicoVeiculo.dto';

@Injectable()
export class CartOrderService {

    constructor(public storage: StorageService) {
    }

    createOrClearCartService(): CartService {
        const cartOrderService: CartService = {items: [] };
        this.storage.setCartService(cartOrderService);
        return cartOrderService;
    }

    getCartService(): CartService {
        let cartOrderService: CartService = this.storage.getCartService();
        if (cartOrderService == null) {
            cartOrderService = this.createOrClearCartService();
        }
        return cartOrderService;
    }

    addService(id: number, placa: string, qtd: number, servico: ServicoVeiculo): CartService {
        const cartOrderService = this.getCartService();
    /*    const position = cartOrderService.items.findIndex(x => x.servico.id === servico.id && x.placa === placa);
        if (position === -1) {
            cartOrderService.items.push({os: id, placa: placa, quantidade: qtd, servico: servico });
        }
        this.storage.setCartService(cartOrderService);*/
        return cartOrderService;
    }

    removeServico(id: number, placa: string, servico: ServicoVeiculo): CartService {
        const cartOrderService = this.getCartService();

   /*     const position = cartOrderService.items.findIndex(x => x.os === id && x.servico.id === servico.id && x.placa === placa);
        if (position !== -1) {
            cartOrderService.items.splice(position, 1);
        }
        this.storage.setCartService(cartOrderService);*/
        return cartOrderService;
    }

    increaseQuantity(id: number, placa: string, servico: ServicoVeiculo): CartService {
        const cartOrderService = this.getCartService();

    /*    const position = cartOrderService.items.findIndex(x => x.os === id && x.servico.id === servico.id && x.placa === placa);
        if (position !== -1) {
            cartOrderService.items[position].quantidade++;
        }
        this.storage.setCartService(cartOrderService);*/
        return cartOrderService;
    }

    decreaseQuantity(id: number, placa: string, servico: ServicoVeiculo): CartService {
     //   let cartOrderService = this.getCartService();

    /*    const position = cartOrderService.items.findIndex(x => x.os === id && x.servico.id === servico.id && x.placa === placa);
        if (position !== -1) {
            cartOrderService.items[position].quantidade--;
            if (cartOrderService.items[position].quantidade < 1) {
                cartOrderService = this.removeServico(id, placa, cartOrderService.items[position].servico);
            }
        }
        this.storage.setCartService(cartOrderService);*/
        return  null;
        // cartOrderService;
    }

    total(id: number, placa: string): number {
        const cartOrderService = this.getCartService();
   /*     for (let i = 0; i < cartOrderService.items.length; i++) {
            if (cartOrderService.items[i].placa === placa && cartOrderService.items[i].os === id) {
                sum += cartOrderService.items[i].servico.valor * cartOrderService.items[i].quantidade;
            }
        }*/
        return null;
        // sum;
    }

    updateIdOrderService(os_id: number, placa: string) {
        const cartOrderService = this.getCartService();
        for (let i = 0; i < cartOrderService.items.length; i++) {
            if (cartOrderService.items[i].placa === placa && cartOrderService.items[i].os === 0) {
                 cartOrderService.items[i].os = os_id;
            }
        }
        this.storage.setCartService(cartOrderService);
    }
}

