import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientesDescVendedor } from './../../models/ClientesDescVendedor';
import { API_CONFIG } from '../../config/api.config';
import { Observable } from 'rxjs';

@Injectable()
export class ClientesDescVendedorService {

  private clientesDescVendedorUrl: string;
  constructor (public http: HttpClient) {
    this.clientesDescVendedorUrl =   `${API_CONFIG.baseUrl}/clientesDescVendedores`;
  }


  buscarPeloId(id: number): Observable<ClientesDescVendedor> {
    return this.http.get<ClientesDescVendedor>(`${this.clientesDescVendedorUrl}/${id}`);
  }

  buscarVendedorAutorizado(codigoCliente: string, codigoVendedor: string): Observable <ClientesDescVendedor> {
    return this.http.get<ClientesDescVendedor>(`${this.clientesDescVendedorUrl}/vendedor/
      ${codigoCliente}/${codigoVendedor}`);
  }

}

