import { API_CONFIG } from './../../config/api.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Produto } from '../../models/Produto.dto';

export class ProdutoFiltro {
    veiculo: string;
    ano: string;
    peca: string;
    modelo: string;
    nome: string;
    codigo: string;
    aplicacao: string;
    codigoMarca: string;
    descricaoMarca: string;
    correlacao: string;
    original: string;
    novocodigo: string;
    codigoInterno: string;
    codigoFabricante: string;
    codigoAntigo: string;
    observacoes: string;
    globalFilter: string;
    pagina: number;
    itensPorPagina = 10;
    constructor(
    veiculo?: string,
    ano?: string,
    peca?: string,
    modelo?: string,
    nome?: string,
    codigo?: string,
    aplicacao?: string,
    codigoMarca?: string,
    descricaoMarca?: string,
    correlacao?: string,
    original?: string,
    novocodigo?: string,
    codigoInterno?: string,
    codigoFabricante?: string,
    codigoAntigo?: string,
    observacoes?: string,
    globalFilter?: string,
    pagina?: number,
    itensPorPagina = 10
    ) {
    this.veiculo = veiculo;
    this.ano = ano;
    this.peca = peca;
    this.modelo = modelo;
    this.nome = nome;
    this.codigo = codigo;
    this.aplicacao = aplicacao;
    this.codigoMarca = codigoMarca;
    this.descricaoMarca = descricaoMarca;
    this.correlacao = correlacao;
    this.original = original;
    this.novocodigo = novocodigo;
    this.codigoInterno = codigoInterno;
    this.codigoFabricante = codigoFabricante;
    this.codigoAntigo = codigoAntigo;
    this.observacoes = observacoes;
    this.globalFilter = globalFilter;
    this.pagina = pagina;
    this.itensPorPagina = itensPorPagina;
    }
}

@Injectable()
export class ProdutoService {
    produtosUrl = '';
    produtosEmpresasUrl = '';
    produtosUrlPesquisar = '';
    produtoDpUrl = '';
    vendaProgressivaUrl = '';
    produtoPesquisadoUrl = '';

    constructor(public http: HttpClient) {
        this.produtosUrl = `${API_CONFIG.baseUrl}/produtos`;
    }

    buscarPorId(id: string) {
        return this.http.get<Produto>(`${this.produtosUrl}/${id}`);
    }

    findPecas(filtro: ProdutoFiltro): Observable<any> {
        let params = new HttpParams({
            fromObject: {
              page: filtro.pagina,
              size: filtro.itensPorPagina
            }
        });

        const aplicacao = filtro.veiculo + ' ' + filtro.ano  + ' ' + filtro.peca + '&';
        const parametro = aplicacao.replace(/\s/g, '&');
        params = params.append('aplicacao', parametro);

        return this.http.get<any>(`${this.produtosUrl}?parametros`, { params });
    }

    getSmallImageFromBucket(id: string): Observable<any> {
        const url = `${API_CONFIG.bucketBaseUrl}/prod${id}-small.jpg`;
        return this.http.get(url, {responseType : 'blob'});
    }

      getImageFromBucket(id: string): Observable<any> {
        const url = `${API_CONFIG.bucketBaseUrl}/prod${id}.jpg`;
        return this.http.get(url, {responseType : 'blob'});
    }

    setFiltroPesquisa(filtro: ProdutoFiltro, params: HttpParams): HttpParams {
        if (filtro.nome && filtro.aplicacao && filtro.descricaoMarca) {
          params = params.append('nome', filtro.nome);
          params = params.append('aplicacao', filtro.aplicacao);
          params = params.append('descricaoMarca', filtro.descricaoMarca);
          this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
        } else if (filtro.nome && filtro.aplicacao) {
          params = params.append('nome', filtro.nome);
          params = params.append('aplicacao', filtro.aplicacao);
          this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
        } else if (filtro.nome && filtro.descricaoMarca) {
          params = params.append('nome', filtro.nome);
          params = params.append('descricaoMarca', filtro.descricaoMarca);
          this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
        } else if (filtro.aplicacao && filtro.descricaoMarca) {
          params = params.append('aplicacao', filtro.aplicacao);
          params = params.append('descricaoMarca', filtro.descricaoMarca);
          this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
        } else if (filtro.nome) {
          params = params.append('nome', filtro.nome);
          this.produtosUrlPesquisar = `${this.produtosUrl}/filter/nome`;
        } else if (filtro.codigo) {
          params = params.append('codigo', filtro.codigo);
          this.produtosUrlPesquisar = `${this.produtosUrl}/filter/codigo`;
        } else if (filtro.aplicacao) {
          params = params.append('aplicacao', filtro.aplicacao);
          this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
        } else if (filtro.descricaoMarca) {
          params = params.append('descricaoMarca', filtro.descricaoMarca);
          this.produtosUrlPesquisar = `${this.produtosUrl}/filter/descricaoMarca`;
        } else if (filtro.original) {
          params = params.append('original', filtro.original);
          this.produtosUrlPesquisar = `${this.produtosUrl}/filter/original`;
        }
        return params;
    }

    similares(filtro: ProdutoFiltro): Observable<any[]> {
        const produtosUrlSimilares = `${this.produtosUrl}/similares/correlacao/codigo`;
        let params = new HttpParams({
            fromObject: {
              page: '0',
              size: '30'
            }
        });
        params = params.append('correlacao', filtro.correlacao);
        params = params.append('codigo', filtro.codigo);
        return this.http.get<any[]>(produtosUrlSimilares, { params});
      }
}
