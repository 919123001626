import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '../config/storage_config';
import { LocalUser } from './../models/Local_User';
import { Cart } from '../models/Cart.dto';
import { CartService } from '../models/Cart-Service.dto';
import { CheckLists } from '../models/Check-lists.dto';

@Injectable()
export class StorageService {

    getLocalUser(): LocalUser | null {
        const usr = localStorage.getItem(STORAGE_KEYS.localUser);
        if (usr == null) {
            return null;
        } else {
            return JSON.parse(usr);
        }
    }

    setLocalUser(obj: LocalUser) {
        if (obj == null) {
            localStorage.removeItem(STORAGE_KEYS.localUser);
        } else {
            localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
        }
    }

    getCart(): Cart | null {
        const str = localStorage.getItem(STORAGE_KEYS.cart);
        if (str != null) {
            return JSON.parse(str);
        } else {
            return null;
        }
    }

    setCart(obj: Cart) {
        if (obj != null) {
            localStorage.setItem(STORAGE_KEYS.cart, JSON.stringify(obj));
        } else {
            localStorage.removeItem(STORAGE_KEYS.cart);
        }
    }

    getCartService(): CartService | null {
        const str = localStorage.getItem(STORAGE_KEYS.cartService);
        if (str != null) {
            return JSON.parse(str);
        } else {
            return null;
        }
    }

    setCartService(obj: CartService) {
        if (obj != null) {
            localStorage.setItem(STORAGE_KEYS.cartService, JSON.stringify(obj));
        } else {
            localStorage.removeItem(STORAGE_KEYS.cartService);
        }
    }

    getCheckList(): CheckLists | null {
        const str = localStorage.getItem(STORAGE_KEYS.checkLists);
        if (str != null) {
            return JSON.parse(str);
        } else {
            return null;
        }
    }

    setCheckList(obj: CheckLists) {
        if (obj != null) {
            localStorage.setItem(STORAGE_KEYS.checkLists, JSON.stringify(obj));
        } else {
            localStorage.removeItem(STORAGE_KEYS.checkLists);
        }
    }
}
