import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ProdutoFiltro } from 'src/services/domain/produto.service';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.page.html',
    styleUrls: ['./search-filter.page.scss'],
})
export class SearchFilterPage implements OnInit {
    organizeby: string;
    dishtype: string;
    dishnationality: string;
    public radiusmiles = 1;
    public onFindProdForm: FormGroup;
    public anos = 2023;
    filtro = new ProdutoFiltro();

    constructor(
        private navCtrl: NavController,
        private modalCtrl: ModalController) { }

    ngOnInit() {
    }

    listProd() {
        const navigationExtras: NavigationExtras = {
            state: {
                param: this.filtro
            }
        };
        this.closeModal();
        this.filtro.ano = this.anos.toString().substring(2, 4);
        this.navCtrl.navigateRoot(['pages/list-parts/list-parts'], navigationExtras);
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

}
