import { ServicoVeiculo } from './../../models/ServicoVeiculo.dto';
import { API_CONFIG } from './../../config/api.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImageUtilService } from '../image-util.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class VeiculoServico {

    servicosVeiculosUrl: string;

    constructor(private http: HttpClient) {
      this.servicosVeiculosUrl = `${environment.apiUrl}/servicosVeiculos`;
    }

    getServicos(servico: string, page: string, pageSize: string) {
        const params = new HttpParams({
            fromObject: {
              descricaoServico: servico,
              page: page,
              size: pageSize
            }
          });
        return this.http.get<any>(`${this.servicosVeiculosUrl}`, { params });
    //   return this.http.get<ServicoVeiculo[]>(`${this.servicosVeiculosUrl}/servicos/${servico}`);
    }

}
