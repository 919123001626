import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { Cidade } from '../../models/Cidade.dto';
import { Observable } from 'rxjs';

@Injectable()
export class CidadeService {

    cidadeUrl: string;

    constructor(public http: HttpClient) {
        this.cidadeUrl = `${API_CONFIG.baseUrl}/cidades`;
    }

    buscarCidadesPeloEstado(estado_id: string): Observable<Cidade[]>  {
        return this.http.get<Cidade[]>(`${this.cidadeUrl}/uf/${estado_id}`);
    }

    buscarCidadePeloNomeEstado(cidade: string, uf: string): Observable<Cidade>  {
        return this.http.get<Cidade>(`${this.cidadeUrl}/${cidade}/${uf}`);
    }
}
