import { Observable, BehaviorSubject } from 'rxjs';
import { OrcamentoOS } from './../../models/OrcamentoOS.dto';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()
export class OrcamentoOSService {

    private urlOrcamentoOS: string;

    aberto = 'AB';
    fechado = 'FE';
    analise = 'AN';
    private dados = new BehaviorSubject({});

    constructor(
        private http: HttpClient,
    ) {
        this.urlOrcamentoOS = `${environment.apiUrl}/orcamentoOS`;
    }

    setDados(orcamentoOS: OrcamentoOS) {
        this.dados.next(orcamentoOS);
    }

    getDados(): Observable<any> {
        return this.dados.asObservable();
    }

    salvar(orcamentoOS: OrcamentoOS) {
        if (!orcamentoOS.id) {
            return this.adicionar(orcamentoOS);
        } else {
            return this.atualizar(orcamentoOS);
        }
    }

    buscarPeloId(orcamentoOS_id: number): Observable<OrcamentoOS> {
        return this.http.get<OrcamentoOS>(`${this.urlOrcamentoOS}/${orcamentoOS_id}`);
    }

    adicionar(orcamentoOS: OrcamentoOS): Observable<OrcamentoOS> {
        return this.http.post<OrcamentoOS>(this.urlOrcamentoOS, orcamentoOS);
    }

    atualizar(orcamentoOS: OrcamentoOS): Observable<OrcamentoOS> {
        return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/${orcamentoOS.id}`, orcamentoOS);
    }

    gerarOS(orcamentoOS: OrcamentoOS) {
        return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/gerarOS/${orcamentoOS.id}`, orcamentoOS);
    }

    listarOrcamentosOSPorClientes(cliente_id: number, placa_id: number, empresa: string, status: string) {

        let params = new HttpParams({
            fromObject: {
                page: '0',
                size: '10'
            }
        });

        params = params.append('empresa', empresa);
        params = params.append('cliente_id', cliente_id.toString());
        params = params.append('placa_id', placa_id.toString());
        params = params.append('status', status);

        return this.http.get<any>(`${this.urlOrcamentoOS}?clientePlacaStatus`, { params });

    }

}
