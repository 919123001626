import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../config/api.config';
import { NaturezaOperacao } from '../../models/NaturezaOperacao.dto';
import { Observable } from 'rxjs';

@Injectable()
export class NaturezaOperacaoService {

    natOperacaoUrl: string;

    constructor(public http: HttpClient) {
        this.natOperacaoUrl = `${API_CONFIG.baseUrl}/naturezasOperacoes`;
    }

    listarNaturezaOperacoes(): Observable<NaturezaOperacao[]>  {
        return this.http.get<NaturezaOperacao[]>(`${this.natOperacaoUrl}/lista`);
    }

    buscarNaturezaOperacaoPeloCodigo(codigo: string) {
        return this.http.get(`${this.natOperacaoUrl}/descricaoNatureza/${codigo}`);
    }
}
