import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { Keyboard } from '@capacitor/keyboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2BRPipesModule } from 'ng2-brpipes';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Network } from '@ionic-native/network/ngx';
// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';
// Services
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthInterceptorProvider } from './../interceptors/auth-interceptor';
import { ErrorInterceptorProvider } from '../interceptors/error-interceptor';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage_service';
import { CartService } from '../services/domain/cart.service';
import { CartOrderService } from 'src/services/domain/cartOrderService';
import { ProdutoService } from './../services/domain/produto.service';
import { UsuarioService } from '../services/domain/usuario.service';
import { ClienteService } from '../services/domain/cliente.service';
import { CidadeService } from '../services/domain/cidade.service';
import { EstadoService } from '../services/domain/estado.service';
import { PreVendaService } from '../services/domain/preVenda.service';
import { PreVendaItemService } from '../services/domain/preVendaItem.service';
import { ClienteFormaPagamentoService } from '../services/domain/clienteFormaPagamentoService';
import { ClientesDescVendedorService } from '../services/domain/clienteDescVendedor.service';
import { FormaPagamentoService } from '../services/domain/formaPagamento.service';
import { OrcamentoOSService } from '../services/domain/orcamentoOSService';
import { ImageUtilService } from '../services/image-util.service';
import { NaturezaOperacaoService } from '../services/domain/naturezaOperacaoService';
import { VendedorService } from '../services/domain/vendedorService';
import { VeiculoServico } from '../services/domain/veiculo.service';
import { TecnicoService } from '../services/domain/tecnico.service';
import { CheckListService } from '../services/domain/checkList.service';
import { CheckListsService } from '../services/domain/checkLists.service';
import { LoginPageModule } from './pages/login/login.module';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent, NotificationsComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        Ng2BRPipesModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ImagePageModule,
        SearchFilterPageModule,
        LoginPageModule,
        AppRoutingModule

    ],
    entryComponents: [NotificationsComponent],
    providers: [
        StatusBar,
        SplashScreen,
        AndroidPermissions,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ErrorHandler,
        FileTransfer,
        WebView,
        {
            provide: 'Camera',
            useFactory: () => Camera,
        },
        {
            provide: 'CameraResultType',
            useValue: CameraResultType,
        },
        {
            provide: 'CameraSource',
            useValue: CameraSource,
        },
        {
            provide: 'Filesystem',
            useFactory: () => Filesystem,
        },
        {
            provide: 'Keyboard',
            useFactory: () => Keyboard,
        },
        Network,
        ProdutoService,
        UsuarioService,
        ClienteService,
        ClientesDescVendedorService,
        CidadeService,
        EstadoService,
        FormaPagamentoService,
        ClienteFormaPagamentoService,
        PreVendaService,
        PreVendaItemService,
        NaturezaOperacaoService,
        VendedorService,
        VeiculoServico,
        TecnicoService,
        OrcamentoOSService,
        CheckListService,
        CheckListsService,
        AuthInterceptorProvider,
        ErrorInterceptorProvider,
        AuthService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        ImageUtilService,
        StorageService,
        CartService,
        CartOrderService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
