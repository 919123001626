import { Usuario } from './../../models/Usuario.dto';
import { API_CONFIG } from './../../config/api.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImageUtilService } from '../image-util.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UsuarioService {

     usuarioUrl: string;

    constructor(
        public http: HttpClient,
        public imageUtilService: ImageUtilService) {
        this.usuarioUrl = `${API_CONFIG.baseUrl}/usuarios`;
    }

    findById(id: string) {
        return this.http.get(`${this.usuarioUrl}/clientes/${id}`);
    }

    findByEmail(email: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${this.usuarioUrl}/buscarPeloEmail/${email}`).pipe(
            map(response => response)
          );
    }

    findByEmailGoogle(email_google: string) {
        return this.http.get<any>(`${this.usuarioUrl}/buscarPeloEmail/${email_google}`);
    }

    insert(obj: Usuario) {
        return this.http.post(
            `${this.usuarioUrl}`,
            obj,
            {
                observe: 'response',
                responseType: 'text'
            }
        );
    }

    atualizar(usuario: Usuario): Observable<Usuario> {
        return this.http.put<Usuario>(`${this.usuarioUrl}/${usuario.codigo}`, usuario);
    }

   uploadPicture(picture: any) {
        const pictureBlob = this.imageUtilService.dataUriToBlob(picture);
        const formData: FormData = new FormData();
        formData.set('file', pictureBlob, 'file.png');
        return this.http.post(
            `${API_CONFIG.baseUrl}/clientes/picture`,
            formData,
            {
                observe: 'response',
                responseType: 'text'
            }
        );
    }

    buscarImagem(directory: string, fileName: string): Observable<Blob> {
        const headers: HttpHeaders = new HttpHeaders({
          'Content-Type': 'image/jpeg',
          'Accept': 'image/jpeg'
        });

        return this.http.get(`${this.usuarioUrl}/download/${directory}/${fileName}`,
          {headers: headers, responseType: 'blob'}).pipe(
            map((res: any) => res)
          );
      }

      adicionarImagem(file: File, directory: string): Observable<any> {
        const formdata: FormData = new FormData();
        formdata.append('file', file);
        return this.http.post(`${this.usuarioUrl}/upload/${directory}`, formdata);
    }

    deletarImagem(directory: string, fileName: string): Observable<any> {
        return this.http.delete(`${this.usuarioUrl}/delete/${directory}/${fileName}`);
    }

  /*  getImageFromBucket(id : string) : Observable<any> {
       let url = `${API_CONFIG.bucketBaseUrl}/cp${id}.jpg`
        return this.http.get(url, {responseType : 'blob'});
    } */

}
