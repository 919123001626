import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login/login',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'pages/:id',
    loadChildren: () => import('./pages/folder.module').then( m => m.FolderPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
],
  exports: [RouterModule]
})

export class AppRoutingModule {}
