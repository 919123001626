
export class ClienteFormaPagamento {

    id: number;
    codigo: string;
    codigoPagamento: string;
    formaFatura: string;
    valorInicial: number;
    valorFinal: number;
    parcelas: string;
    desdobramentos: String;


    constructor(
        codigo?: string,
        codigoPagamento?: string,
    ) {
        this.codigo = codigo;
        this.codigoPagamento = codigoPagamento;
    }
}
